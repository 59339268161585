import { customerService } from 'services';

export const DeviceStatusEnum = {
  Offline: 0,
  Online: 1,
  Error: 2,
};

export const SensorStatusEnum = {
  Ok: 0,
  Error: 1,
};

export const GetSystemStatus = (systemId, fetchStatus) => {
  if (fetchStatus) {
    const customer = customerService.customerValue;
    let sensors = undefined;
    if (customer === null || customer.locations === null) {
      return ({ deviceStatus: DeviceStatusEnum.Online, sensorStatus: SensorStatusEnum.Ok });
    }
    customer.locations.map(location => {
      const panels = location.systems.find(system => system.id === systemId)?.panels;
      if (panels) {
        sensors = panels.flatMap(panel => panel);
      }
    }
    );

    let deviceId = undefined;
    customer.locations.find(location =>
      deviceId = location.systems.find(system => system.id === systemId)
      && location.systems.find(system => system.id === systemId).deviceId
    );

    let deviceStatus = fetchStatus.devices.find(x => x.id === deviceId) !== undefined
      ? fetchStatus.devices.find(x => x.id === deviceId).status
      : DeviceStatusEnum.Online;

    let sensorStatus = SensorStatusEnum.Ok;

    fetchStatus.sensors?.forEach(s => {
      if (sensors.find(x => x.sensorId === s.id)) {
        sensorStatus = SensorStatusEnum.Error;
      }
    });


    return ({ deviceStatus, sensorStatus });
  }
  return ({ deviceStatus: DeviceStatusEnum.Online, sensorStatus: SensorStatusEnum.Ok });
};

export const GetLocationStatus = (locationId, fetchStatus) => {
  // TODO; need to add customer id at unput so it can handle admin page. 
  if (fetchStatus) {
    const customer = customerService.customerValue;
    var location = undefined;
    if (customer && customer.locations && customer.locations.length > 0) {


      customer.locations.find(l => {
        if (l.id === locationId) { location = l; }
      }
      );
      var statuses = [];
      location.systems.forEach(s => {
        statuses.push(GetSystemStatus(s.id, fetchStatus));
      });

      var sensorStatus = SensorStatusEnum.Ok;
      var deviceStatus = DeviceStatusEnum.Online;



      statuses.forEach(status => {

        if (status.sensorStatus === SensorStatusEnum.Error) {
          sensorStatus = SensorStatusEnum.Error;
        }

        if (status.deviceStatus === DeviceStatusEnum.Error) {
          deviceStatus = DeviceStatusEnum.Error;
        }

        if (status.deviceStatus === DeviceStatusEnum.Offline
          && status.deviceStatus !== DeviceStatusEnum.Error) {
          deviceStatus = DeviceStatusEnum.Offline;
        }
      });

      return ({ deviceStatus, sensorStatus });
    }
  }
  return ({ deviceStatus: DeviceStatusEnum.Online, sensorStatus: SensorStatusEnum.Ok });
};

export const GetCustomerStatus = (customerId, fetchStatus) => {
  if (fetchStatus) {
    customerService.getById(customerId).then((customer) => {
      var sensorStatus = SensorStatusEnum.Ok;
      var deviceStatus = DeviceStatusEnum.Online;
      if (customer) {
        var statuses = [];
        customer?.locations?.forEach(l => {
          statuses.push(GetLocationStatus(l.id, fetchStatus));
        });

        statuses.forEach(status => {

          if (status.sensorStatus === SensorStatusEnum.Error) {
            sensorStatus = SensorStatusEnum.Error;
          }

          if (status.deviceStatus === DeviceStatusEnum.Error) {
            deviceStatus = DeviceStatusEnum.Error;
          }

          if (status.deviceStatus === DeviceStatusEnum.Offline
            && status.deviceStatus !== DeviceStatusEnum.Error) {
            deviceStatus = DeviceStatusEnum.Offline;
          }
        });
      }
      return ({ deviceStatus, sensorStatus });
    });
  }
  return ({ deviceStatus: DeviceStatusEnum.Online, sensorStatus: SensorStatusEnum.Ok });
};


export const GetGetDeviceStatus = (deviceId, fetchStatus) => {
  if (fetchStatus && fetchStatus.devices) {

    var deviceStatus = fetchStatus.devices.find(x => x.id === deviceId) !== undefined
      ? fetchStatus.devices.find(x => x.id === deviceId).status
      : DeviceStatusEnum.Online;

    var sensorStatus = SensorStatusEnum.Ok;
    return ({ deviceStatus, sensorStatus });
  }
  return ({ deviceStatus: DeviceStatusEnum.Online, sensorStatus: SensorStatusEnum.Ok });
};

