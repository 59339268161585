import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

export function Select({state, options, setState, label = null}){
  return(
    <Listbox value={state} onChange={setState}>
      <div className="z-99 relative w-full">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-8 text-left bg-white rounded-lg border border-gray-300 hover:border-gray-500 focus-visible:border-sky-600 cursor-default hover:cursor-pointer focus:outline-none leading-tight">
          <span className="block truncate">{label? label: 'Vis'} {state} </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100 " leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border border-gray-200 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none leading-tight">
            {options.map((pageSize) => (
              <Listbox.Option
                key={pageSize}
                className={({ active }) =>
                  `${active ? 'text-sky-600' : 'text-gray-800'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                }
                value={pageSize}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                      {pageSize}
                    </span>
                    {selected ? (
                      <span
                        className={`${active ? 'text-sky-600' : 'text-ed.blue'}
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
