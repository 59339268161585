import { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { systemService } from 'services/system.service';
import { defaultTube, Tube } from './items/Tube';
import { Box, defaultBox } from './items/Box';
import { Arrows, defaultArrows } from './items/Arrows';
import { Arrow, defaultArrow } from './items/Arrow';
import { defaultLine, Line } from './items/Line';
import { CurvedArrow, defaultCurvedArrow } from './items/CurvedArrow';
import { defaultSensor, Sensor } from './items/Sensor';
import { defaultText, Text } from './items/Text';
import { Modal } from '../Modal';
import { ItemCanvas } from './ItemCanvas';
import { Field, Form, Formik } from 'formik';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export function CanvasEdit({ page }) {
  const [items, setItems] = useState(page && page.items ? page.items : []);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const historyStore = useRef([]);
  const historyPointer = useRef(-1);

  const history = {
    forward: () => historyForward(),
    back: () => historyBack(),
    store: () => {
      return historyStore.current;
    },
    add: (items) => historyStore.current.unshift(items),
    pointer: () => {
      return historyPointer.current;
    },
    setPointer: (value) => {
      historyPointer.current = value;
    },
  };

  function historyBack() {
    if (history.pointer() === -1) {
      history.add(items);
      history.setPointer(history.pointer() + 1);
    }
    if (history.pointer() + 1 < history.store().length) {
      history.setPointer(history.pointer() + 1);
    }
    setItems(history.store()[history.pointer()]);
  }

  function historyForward() {
    if (history.pointer() - 1 >= 0) {
      history.setPointer(history.pointer() - 1);
      setItems(history.store()[history.pointer()]);
    }
  }

  function savePage() {
    systemService
      .saveCanvas({
        id: page.id,
        items: items,
      })
      .then(() => {
        toast.success('Saved canvas.');
      })
      .catch(() => toast.error('Failed to save :('));
  }

  function saveAs(values, { setSubmitting }) {
    setSubmitting(true);
    systemService
      .saveCanvas({
        title: values.title,
        items: items,
      })
      .then(() => {
        setSubmitting(false);
        setIsOpen(false);
        toast.success(`Saved page "${values.title}"`);
      })
      .catch(() => toast.error('Failed to save :('));
  }

  function copyItem() {
    setSelected((selected) => {
      history.add(items);
      const newItems = [];
      const newSelected = [];
      selected.forEach((key, idx) => {
        newItems.push({ ...items[key], x: items[key].x + 20, y: items[key].y + 20 });
        newSelected.push(items.length + idx);
      });
      setItems([...items, ...newItems]);

      return newSelected;
    });
  }

  function removeItems() {
    setSelected((selected) => {
      history.add(items);
      setItems([...items.filter((item) => !selected.includes(items.indexOf(item)))]);
      return [];
    });
  }

  function setDimension(e) {
    const alt = e.target.id;
    setItems(
      items.map((item, idx) => {
        return {
          ...item,
          x:
            alt === 'x' && selected[0] === idx
              ? parseInt(e.target.value)
                ? parseInt(e.target.value)
                : item.x
              : item.x,
          y:
            alt === 'y' && selected[0] === idx
              ? parseInt(e.target.value)
                ? parseInt(e.target.value)
                : item.x
              : item.y,
          w:
            alt === 'w' && selected[0] === idx
              ? parseInt(e.target.value)
                ? parseInt(e.target.value)
                : item.x
              : item.w,
          h:
            alt === 'h' && selected[0] === idx
              ? parseInt(e.target.value)
                ? parseInt(e.target.value)
                : item.x
              : item.h,
        };
      })
    );
  }

  function itemLayer(dir) {}

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Lagre">
        <div>
          <Formik initialValues={{ title: page && page.title && page.title, category: '' }} onSubmit={saveAs}>
            {({ isSubmitting }) => (
              <Form className="space-y-3">
                <div>
                  <label className="block tracking-wide text-gray-700 text-xs font-bold text-center">Tittel</label>
                  <Field
                    type="text"
                    name="title"
                    placeholder={(!page || !page.title) && 'Beskrivende tittel'}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div>
                  <label className="block tracking-wide text-gray-700 text-xs font-bold text-center">Kategori</label>
                  <Field
                    as="select"
                    name="category"
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value={0}>Ventilasjon</option>
                    <option value={1}>Vann og fukt</option>
                    <option value={2}>Brann</option>
                  </Field>
                </div>
                <div className="flex place-content-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-full border-2 border-green-500 px-3 py-1"
                  >
                    Lagre
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <div className="flex m-1 space-x-1">
        <div className="flex-auto w-3/6 border-2 border-black border-opacity-10 rounded p-1 flex">
          <div className="w-5/6">
            <h1 className="font-bold text-center">Elementer</h1>
            <svg id="ItemList" viewBox="0 0 2000 400">
              <Tube
                key={'select_tube'}
                id="tube"
                state={{
                  ...defaultTube,
                  x: 170,
                  y: 100,
                  w: 260,
                  h: 110,
                  disabled: true,
                }}
              />
              <Box
                key="select_box"
                id="box"
                state={{
                  ...defaultBox,
                  x: 420,
                  y: 100,
                  w: 110,
                  h: 140,
                  disabled: true,
                }}
              />
              <Arrows
                key="select_arrows"
                id="arrows"
                state={{
                  ...defaultArrows,
                  x: 610,
                  y: 100,
                  w: 110,
                  h: 150,
                  disabled: true,
                }}
              />
              <Text
                key="select_text"
                id="text"
                state={{
                  ...defaultText,
                  x: 820,
                  y: 100,
                  fontSize: 120,
                  disabled: true,
                }}
              />
              <Arrow
                key="select_arrow"
                id="arrow"
                state={{
                  ...defaultArrow,
                  x: 1050,
                  y: 100,
                  w: 150,
                  disabled: true,
                }}
              />
              <Line
                key="select_line"
                id="line"
                state={{
                  ...defaultLine,
                  x: 1200,
                  y: 100,
                  w: 150,
                  r: 100,
                  disabled: true,
                }}
              />
              <CurvedArrow
                key="select_curvedArrow"
                id="curvedArrow"
                state={{
                  ...defaultCurvedArrow,
                  x: 1360,
                  y: 100,
                  w: 130,
                  h: 180,
                  disabled: true,
                }}
              />
            </svg>
          </div>
          <div>
            <h1 className="font-bold text-center">Sensorer</h1>
            <svg id="ItemList" viewBox="0 0 200 300">
              <Sensor
                key={'select_sensor'}
                id="sensor"
                state={{
                  ...defaultSensor,
                  x: 100,
                  y: 50,
                  w: 100,
                  h: 70,
                  disabled: true,
                }}
              />
            </svg>
          </div>
        </div>
        <div className="w-2/6 border-2 border-black border-opacity-10 rounded p-1">
          <h1 className="font-bold text-center">Tilpass</h1>
          {selected.length >= 1 && (
            <div className="flex space-x-2">
              <div className="w-1/3">
                {selected.length === 1 && items[selected[0]] && (
                  <form onChange={setDimension}>
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-0 text-center">
                      Dimensjoner
                    </label>
                    <div className="flex-col">
                      <div className="w-full flex">
                        <div className="flex-auto">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold text-center">x</label>
                          <input
                            id="x"
                            defaultValue={selected[0] && items[selected[0]] && items[selected[0]].x}
                            className="shadow appearance-none border rounded max-h-6 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        {!items[selected].text && (
                          <div className="flex-auto">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold text-center">w</label>
                            <input
                              id="w"
                              defaultValue={selected[0] && items[selected[0]] && items[selected[0]].w}
                              className="shadow appearance-none border rounded max-h-6 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-full flex">
                        <div className="h-1/2">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold text-center">y</label>
                          <input
                            id="y"
                            defaultValue={selected[0] && items[selected[0]] && items[selected[0]].y}
                            className="shadow appearance-none border rounded max-h-6 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        {!items[selected].text && (
                          <div className="flex-auto">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold text-s text-center">
                              h
                            </label>
                            <input
                              id="h"
                              defaultValue={selected[0] && items[selected[0]] && items[selected[0]].h}
                              className="shadow appearance-none border rounded max-h-6 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>

              <div className="w-1/3 flex-col">
                {selected.length === 1 && (
                  <div>
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold text-center">
                      Valg
                    </label>
                    {selected[0] && items[selected[0]] && (
                      <div className="">
                        <div className="">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold text-center">
                            Tekst
                          </label>
                          <input
                            defaultValue={selected[0] && items[selected[0]] && items[selected[0]].text}
                            onChange={(e) =>
                              setItems(
                                items.map((item) => {
                                  if (items.indexOf(item) === selected[0]) {
                                    return {
                                      ...item,
                                      text: e.target.value,
                                    };
                                  }
                                  return item;
                                })
                              )
                            }
                            className="shadow appearance-none border rounded max-h-6 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                        <div className="">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold text-center">
                            Størrelse
                          </label>
                          <input
                            defaultValue={selected[0] && items[selected[0]] && items[selected[0]].fontSize}
                            onChange={(e) =>
                              setItems(
                                items.map((item) => {
                                  if (items.indexOf(item) === selected[0]) {
                                    return {
                                      ...item,
                                      fontSize: e.target.value,
                                    };
                                  }
                                  return item;
                                })
                              )
                            }
                            className="max-h-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="w-1/3 flex-col">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold text-center">
                  Verktøy
                </label>
                <div className="flex-col space-y-5 text-sm">
                  <div className=""></div>
                  <div className="flex space-x-1 place-content-center">
                    <div className="">
                      <button
                        onClick={copyItem}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded-full"
                      >
                        Kopier
                      </button>
                    </div>
                    <div className="">
                      <button
                        onClick={removeItems}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded-full"
                      >
                        Fjern
                      </button>
                    </div>
                  </div>
                  <div className="flex space-x-1 place-content-center">
                    <div className="">
                      <button
                        onClick={itemLayer('up')}
                        className="border-blue-500 border-2 hover:bg-blue-500 py-1 px-3 rounded-full"
                      >
                        Flytt frem
                      </button>
                    </div>
                    <div className="">
                      <button
                        onClick={itemLayer('down')}
                        className="border-blue-500 border-2 hover:bg-blue-500 py-1 px-3 rounded-full"
                      >
                        Flytt tilbake
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex-auto w-1/6 border-2 border-black border-opacity-10 rounded p-1 space-y-2">
          <div className="relative">
            <h2 className="font-bold text-center">Innstillinger</h2>
            <div className="w-6 items-center flex absolute right-0 top-0">
              <ArrowTopRightOnSquareIcon />
            </div>
          </div>
          <div className="w-full flex place-content-center">
            <div className="flex space-x-1">
              {/* <label className="w-full block uppercase tracking-wide text-gray-700 text-xs font-bold text-center">Lagre</label> */}
              <button
                onClick={() => {
                  setSelected([]);
                  setItems([]);
                }}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-full"
              >
                Clear
              </button>
              <button
                onClick={() => savePage()}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded-full"
              >
                Lagre
              </button>
              <button
                onClick={() => setIsOpen(true)}
                className="border-2 border-green-500 hover:bg-green-500 font-bold py-1 px-3 rounded-full"
              >
                Kopi
              </button>
            </div>
          </div>
          <div>
            <label className="w-full block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
              Historikk
            </label>
            <div className="flex">
              <div className="w-1/2">
                <svg
                  onClick={historyBack}
                  className="float-right mr-1 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-3.31 0-6.291 1.353-8.459 3.522l-2.48-2.48-1.061 7.341 7.437-.966-2.489-2.488c1.808-1.808 4.299-2.929 7.052-2.929 5.514 0 10 4.486 10 10s-4.486 10-10 10c-3.872 0-7.229-2.216-8.89-5.443l-1.717 1.046c2.012 3.803 6.005 6.397 10.607 6.397 6.627 0 12-5.373 12-12s-5.373-12-12-12z" />
                </svg>
              </div>
              <div className="w-1/2">
                <svg
                  onClick={historyForward}
                  className="float-left ml-1 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c3.31 0 6.291 1.353 8.459 3.522l2.48-2.48 1.061 7.341-7.437-.966 2.489-2.489c-1.808-1.807-4.299-2.928-7.052-2.928-5.514 0-10 4.486-10 10s4.486 10 10 10c3.872 0 7.229-2.216 8.89-5.443l1.717 1.046c-2.012 3.803-6.005 6.397-10.607 6.397-6.627 0-12-5.373-12-12s5.373-12 12-12z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ItemCanvas
        items={items}
        setItems={setItems}
        selected={selected}
        setSelected={setSelected}
        history={history}
        // historyPointer={historyPointer}
        // historyBack={historyBack}
        // historyForward={historyForward}
        removeItems={removeItems}
        copyItem={copyItem}
      />
    </>
  );
}
